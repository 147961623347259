import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useOutlet } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import logo from "./logo.png";

const Main = ({ routes }) => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const [isIndex, setIsIndex] = useState(currentOutlet.props.children.props.match.route.index);
  useEffect(() => {
    setIsIndex(currentOutlet.props.children.props.match.route.index);
  }, [currentOutlet]);
  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {};
  const indexClass = isIndex ? "index" : "not-index";
  return (
    <div className="main">
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          classNames="container"
        >
          {() => (
            <div ref={nodeRef} className={`container ${indexClass}`}>
              <header>
                <div className="logo-wrapper">
                  <Link to="/">
                    <img className="logo" alt="logo" src={logo} width="100" height="100" />
                  </Link>
                </div>
                <nav>
                  <ul className="nav-list">
                    <li className="item">
                      <NavLink to="about" className="link link--carme">
                        About
                        <svg className="link__graphic link__graphic--stroke link__graphic--scribble" width="100%" height="9" viewBox="0 0 101 9"><path d="M.426 1.973C4.144 1.567 17.77-.514 21.443 1.48 24.296 3.026 24.844 4.627 27.5 7c3.075 2.748 6.642-4.141 10.066-4.688 7.517-1.2 13.237 5.425 17.59 2.745C58.5 3 60.464-1.786 66 2c1.996 1.365 3.174 3.737 5.286 4.41 5.423 1.727 25.34-7.981 29.14-1.294" pathLength="1"/></svg>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </header>
              {currentOutlet}
              <footer>
                <ul className="footer-list">
                  <li className="item">
                    <NavLink to="work" className="link link--carme">
                      Work
                      <svg className="link__graphic link__graphic--stroke link__graphic--scribble" width="100%" height="9" viewBox="0 0 101 9"><path d="M.426 1.973C4.144 1.567 17.77-.514 21.443 1.48 24.296 3.026 24.844 4.627 27.5 7c3.075 2.748 6.642-4.141 10.066-4.688 7.517-1.2 13.237 5.425 17.59 2.745C58.5 3 60.464-1.786 66 2c1.996 1.365 3.174 3.737 5.286 4.41 5.423 1.727 25.34-7.981 29.14-1.294" pathLength="1"/></svg>
                    </NavLink>
                  </li>
                  <li className="item">
                    <NavLink to="contact" className="link link--carme">
                      Contact
                      <svg className="link__graphic link__graphic--stroke link__graphic--scribble" width="100%" height="9" viewBox="0 0 101 9"><path d="M.426 1.973C4.144 1.567 17.77-.514 21.443 1.48 24.296 3.026 24.844 4.627 27.5 7c3.075 2.748 6.642-4.141 10.066-4.688 7.517-1.2 13.237 5.425 17.59 2.745C58.5 3 60.464-1.786 66 2c1.996 1.365 3.174 3.737 5.286 4.41 5.423 1.727 25.34-7.981 29.14-1.294" pathLength="1"/></svg>
                    </NavLink>
                  </li>
                </ul>
              </footer>
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default Main;
