import React from "react";

function Home() {
  return (
    <>
    <div className="title-wrapper">
      <h1 data-heading="Jamison Sheppard">
        Jamison Sheppard
      </h1>
    </div>
  </>
  );
} 

export default Home;
