import React from "react";
import { useLocation } from "react-router";

function Harveymilling() {
  const { state } = useLocation();
  return (
    <>
      {state && (
        <>
          <div className="title-wrapper">
            <h1>Harvey Milling</h1>
          </div>
          <div className="content-wrapper">
          <div className="hero-wrapper">
            <img src={state.hero} alt={state.name} className="hero-image" width={900} height={500}/>
          </div>
          <div className="work-wrapper">
            <div className="subtitle-wrapper">
              <h2>Technology</h2>
              {state.technology.map((technology, index) => {
                return <span key={index}>{technology}</span>;
              })}
              <h2>Deliverables</h2>
              {state.deliverables.map((deliverable, index) => {
                return <span key={index}>{deliverable}</span>;
              })}
            </div>
            <div className="content">
              <h3>Taking retail online</h3>
                <h4>Introduction</h4>
                  <p>Harvey Milling is a family-owned feed and farm supply store in Carson City, Michigan. They needed a way to offer curbside pickup and sell their products online during the pandemic. They also needed to be able to manage the store themselves after the initial build. Squarespace was chosen for its flexibility, robust theming and default design, and budget-approved price.</p>
                <h4>Background</h4>
                  <p>Throughout my career journey, I've had the privilege of working with Harvey's, engaging in a wide range of projects from print advertising to packaging design. From the onset, I've enjoyed the diverse project categories and their ability to grow my skillset, stay up-to-date on design patterns and industry trends, and enabling me collaborate across teams to deliver visually compelling solutions.</p>
                <figure className="image-wrapper">
                  <img src="/harveymilling-views.jpg" alt="AgraScout views" width="800" height="600" />
                  <figcaption>Harvey Milling brand guidelines and examples</figcaption>
                </figure>
                <h4>Challenges</h4>
                  <p>We had to launch the Harvey Milling online store in just a couple days so having a system for automating asset production, information architecture, writing copy, and delegating tasks was paramount. Within a year of launching we had hit Squarespace's product limit on their last version and needed to upgrade to a breaking change, forcing us to rebuild the entire site again.</p>
                <h4>Technical Solutions</h4>
                <p>Using Squarespace and some custom templates, we were able to quickly set up a store that was easy to manage and update. We also used the platform's built-in SEO tools and attention to detail to help Harvey's get found online, landing it on the first page for several products. We also set up a system for fulfilling orders and wrote support documentation for the staff. We learned the intricacies of e-commerce: state-by-state sale tax accounting, shipping rates and zones, payment fraud, analytics, paid advertising, and more we thankfully haven't got to yet.</p>
                <p></p>
              </div>
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default Harveymilling;
