import React from "react";
import { useLocation } from "react-router";

function Agrascout() {
  const { state } = useLocation();
  return (
    <>
    {state && (
      <>
      <div className="title-wrapper">
        <h1>AgraScout</h1>
      </div>
      <div className="content-wrapper">
        <div className="hero-wrapper">
          <img src={state.hero} alt={state.name} className="hero-image" width={900} height={500}/>
        </div>
        <div className="work-wrapper">
          <div className="subtitle-wrapper">
            <h2>Technology</h2>
            {state.technology.map((technology, index) => {
              return <span key={index}>{technology}</span>;
            })}
            <h2>Deliverables</h2>
            {state.deliverables.map((deliverable, index) => {
              return <span key={index}>{deliverable}</span>;
            })}
          </div>
          <div className="content">
            <h3>Crop scouting app</h3>
            <h4>Introduction</h4>
              <p>As a novice developer eagerly looking to use my classroom experience in a practical setting, I undertook my first professional project while still in college. This experience marked my entry into the industry and laid the groundwork for my ongoing journey to master the skills of software development.</p>
            <h4>Background</h4>
                <p>I was hired in part because of my web design and crop scouting experience. I used my in-field knowledge to guide product design with Neucadia's co-founders. Together we built a hybrid app that was easy to use for scouts, agronomists, and growers, and even earned us praise from <a class="link" href="https://www.croplife.com/editorial/matt-hopkins/10-best-new-agriculture-apps-for-2015/">industry outlets</a>.</p>
                <div className="image-row-wrapper">
                  <img src="/weed.png" alt="Weed marker" width="50" height="75" />
                  <img src="/other.png" alt="other marker" width="50" height="75" />
                  <img src="/disease.png" alt="disease marker" width="50" height="75" />
                  <img src="/insect.png" alt="insect marker" width="50" height="75" />
                  <img src="/enter.png" alt="enter marker" width="50" height="75" />
                </div>
            <h4>Challenges</h4>
              <p>One of the biggest challenges was working with a small team and a tight budget. We had to be creative with our resources and time. We also had to be mindful of the fact that our users would be using the app in the field, where internet connectivity is often spotty. This meant we had to build a robust low-data mode for the app.</p>
            <h4>Technical Solutions</h4>
                <p>I built out a brand asset library using SMACSS on top of Bootstrap. The app was used on desktops all the way down to 300-pixel-wide Androids so we utilized mobile-first, responsive design to provide a quality experience across platforms and screen sizes. We had users across three continents so we learned a lot about internationalization and accessibility for multi-national applications. AgraScout was used by independent agronomists, retailers, and producers so I learned how important early permissions and authentication decisions can be. I was also often the boots-on-the-ground sales and support as well as writing software so I had a unique perspective on expectation versus delivery.</p>
                <figure className="image-wrapper">
                  <img src="/agrascout-views.jpg" alt="AgraScout views" width="800" height="600" />
                  <figcaption>AgraScout "home" page and various brand assets</figcaption>
                </figure>
          </div>
        </div>
      </div>
      </>
    )}
    </>
  );
};

export default Agrascout;
