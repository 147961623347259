import React from "react";
import { useLocation } from "react-router";

function Tillerman() {
  const { state } = useLocation();
  return (
    <>
      {state && (
        <>
          <div className="title-wrapper">
            <h1>Tillerman Seeds</h1>
          </div>
          <div className="content-wrapper">
            <div className="hero-wrapper">
              <img src={state.hero} alt={state.name} className="hero-image" width={900} height={500}/>
            </div>
            <div className="work-wrapper">
              <div className="subtitle-wrapper">
                <h2>Technology</h2>
                {state.technology.map((technology, index) => {
                  return <span key={index}>{technology}</span>;
                })}
                <h2>Deliverables</h2>
                {state.deliverables.map((deliverable, index) => {
                  return <span key={index}>{deliverable}</span>;
                })}
              </div>
              <div className="content">
                <h3>Professional under pressure</h3>
                <h4>Introduction</h4>
                <p>Tillerman Seeds was an investment brand for a private-equity firm. The brand needed a logo, business cards, and a website where they could inconspicuously attract new acquisitions—but everything was hush-hush because they were still fundraising and wanted to keep the identities of the investors and potential acquisitions private.</p>
                <h4>Background</h4>
                <p>Because of the name, the only request on the branding front was for the primary logo to be a tillering man. I iterated through a few different shapes before landing on the one they chose. Tillerman, as the holding company for regional seed companies needed to look like a timeless ag company itself. I used a slightly condensed Herchey with a thick flourish underneath to give the wordmark a classic, natural feeling.</p>
                <figure className="image-wrapper">
                  <img src="/tillerman-views.jpg" alt="Tillerman business card" width="800" height="600" />
                  <figcaption>Tillerman Seeds business cards</figcaption>
                </figure>
                <h4>Challenges</h4>
                <p>There was almost no direction given from Tillerman so I had freedom to pursue any direction I thought fit the project. But with that lack of direction meant that changes were frequently requested and needed to happen immediately with the ongoing acquisitions so I reached for the Jamstack so I could work in a serverless environment and push up changes immediately.</p>
                <h4>Technical Solutions</h4>
                <p>I really liked what I'd heard of Hugo: the speed, ease of use, and configurability were attractive as someone who was building the project himself. The Tillerman site rendered in under a second and using the huge theme library I was able to build something fast that still looked professional and branded for Tillerman.</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Tillerman;
