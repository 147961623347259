/**
 * Utilities Class has some functions that are needed throughout the entire application
 */
export class Utils {
  /**
   * Returns a random integer between a given minimum and maximum value
   * @param {number} min - The minimum value, can be negative
   * @param {number} max - The maximum value, can be negative
   * @return {number}
   */
  static getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
