import React from "react";

function Contact() {
  return (
    <>
      <div className="title-wrapper">
        <h1>Contact</h1>
        <div className="contact-wrapper">
          <div className="mx-container">
            <p className="lead">Leave me a message and I'll do my best to get right back to you.</p>
            <form name="contact" class="row" method="POST">
              <input className="hidden" type="hidden" name="form-name" value="contact" />
              <div class="form-group col-md-6">
                <input type="text" name="name" id="name" class="form-control" placeholder="Name"/>
              </div>
              <div class="form-group col-md-6">
                <input type="email" name="email" id="email" class="form-control" placeholder="Email Address"/>
              </div>
              <div class="form-group col-12">
                <textarea name="message" id="message" class="form-control" placeholder="Type Your Message Here ..."></textarea>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-primary" type="submit">Send now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
