import { useState, useEffect } from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useParams } from "react-router-dom";

const BlogPage = () => {
  const [convertedMarkdown, setConvertedMarkdown] = useState();
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    const loadMarkdown = async () => {
      try {
        const file = await import(`./blogs/${slug}.md`);
        const res = await fetch(file.default);
        const content = await res.text();
        setConvertedMarkdown(content);
        setLoading(false);
      } catch (err) {
        setConvertedMarkdown(`# Error loading ${slug}.md\n\n${err}`);
        setLoading(false);
      }
    };
    loadMarkdown();
  }, [slug]);
  return (
    <div className="blog-wrapper">
      {convertedMarkdown && !loading && (
        <Markdown
          components={{
            code: function code({
              node,
              inline,
              className,
              children,
              ...props
            }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  style={solarizedlight}
                  language={match[1]}
                  PreTag="div"
                  wrapLongLines={true}
                  {...props}
                />
              ) : (
                <div className="inline-code">
                  <code className={className} {...props}>
                    {children}
                  </code>
                </div>
              );
            },
          }}
        >
          {convertedMarkdown}
        </Markdown>
      )}
    </div>
  );
};

export default BlogPage;
