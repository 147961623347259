import React from "react";
import { useLocation } from "react-router";

function Harveysseed() {
  const { state } = useLocation();
  return (
    <>
      {state && (
        <>
          <div className="title-wrapper">
            <h1>Harvey's Seed</h1>
          </div>
          <div className="content-wrapper">
            <div className="hero-wrapper">
              <img
                src={state.hero}
                alt={state.name}
                className="hero-image"
                width={900}
                height={500}
              />
            </div>
            <div className="work-wrapper">
              <div className="subtitle-wrapper">
                <h2>Technology</h2>
                {state.technology.map((technology, index) => {
                  return <span key={index}>{technology}</span>;
                })}
                <h2>Deliverables</h2>
                {state.deliverables.map((deliverable, index) => {
                  return <span key={index}>{deliverable}</span>;
                })}
              </div>
              <div className="content">
                <h3>Harvey's Redux</h3>
                <h4>Introduction</h4>
                <p>
                  I was tasked with the project of building a seed brand for the
                  Harvey's portfolio. The goal was to create a timeless,
                  principled, strong brand that shared the Harvey's name and
                  honeycomb symbolism but was distinct from the other brands in
                  the portfolio.
                </p>
                <h4>Background</h4>
                <p>
                  I started my design career at the previous iteration of
                  Harvey's which had been named Harvey's Ag and was sold to a
                  national conglomerate. The new Harvey's Seed was a return to
                  the original family-owned business and I was excited to come
                  back to the vintage spirit of grit and solidity for the brand.
                </p>
                <figure className="image-wrapper">
                  <img
                    src="/harveysseed-views.jpg"
                    alt="Harvey's Seed assets"
                    width="800"
                    height="600"
                  />
                  <figcaption>
                    Harveys's Seed digital and print assets
                  </figcaption>
                </figure>
                <h4>Challenges</h4>
                <p>
                  Harvey's Seed keeps expanding their offerings so one of the
                  first things I had to do after the initial launch was add
                  custom templating to the Hugo site that imported cover crop
                  data and built out the pages for each product. Harvey's Seed
                  also needed to update their 3rd party tech sheets for their
                  salespeople but lacked support from their dealers.
                </p>
                <h4>Technical Solutions</h4>
                <p>
                  Using create-next-app and ReactPDF, in just a dozen hours or
                  so I was able to rebuild their entire portfolio of silage and
                  hybrid tech sheets for their salesforce.
                </p>
                <p>
                  Besides nextjs, I had to build multiple custom templates for
                  the Hugo landing page and product pages. I also had to build a
                  custom letterhead template for press releases and memos and
                  implemented a custom pattern design for business cards and
                  marketing materials.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Harveysseed;
