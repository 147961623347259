import * as PIXI from "pixi.js";
import { Droplet } from "./Droplet";
/**
 * LargeDroplet Class
 */
export class LargeDroplet extends Droplet {
  /**
   * Droplet constructor
   */
  constructor(texture) {
    super(texture);

    this.dropletVelocity = new PIXI.Point(0, 0);
    this.toBeRemoved = false;
    this.targetMass = 0;
  }
}
