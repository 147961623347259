export const work = [
  {
    name: "FarmCycle",
    technology: [
      "Expo",
      "React Native",
      "AWS Amplify",
      "AWS AppSync",
      "GraphQL",
      "Jest",
      "Prisma",
      "tRPC",
      "nextJS",
      "Kinde",
      "turbo",
      "formik",
      "tailwind",
    ],
    deliverables: [
      "brand assets",
      "product UI/UX",
      "API design",
      "website",
      "software engineering",
    ],
    tags: ["React native", "next", "typescript", "tailwind"],
    thumbnail: "farmcycle-thumb.png",
    hero: "/farmcycle-hero.jpg",
    slug: "farmcycle",
    border: "#659ac2",
    description:
      "A nutrient recordkeeping app for livestock producers built on the t3 stack.",
  },
  {
    name: "Crop Inspector",
    technology: ["React Native", "tcomb-form-native"],
    deliverables: [
      "brand assets",
      "icon design",
      "product UI/UX",
      "software development",
    ],
    tags: ["React native"],
    thumbnail: "cropinspector-thumb.png",
    hero: "/cropinspector-hero.jpg",
    slug: "cropinspector",
    border: "#37c968",
    description:
      "An iOS and Android app built with React Native for in-field inspection of corn, soy, and wheat seed production.",
  },
  {
    name: "Harvey's Seed",
    technology: ["React", "Nextjs", "tailwind", "ReactPDF", "hugo"],
    deliverables: ["logo", "brand assets", "website", "business card"],
    tags: ["next", "typescript", "tailwind", "hugo"],
    thumbnail: "harveysseed-thumb.png",
    hero: "/harveysseed-hero.jpg",
    slug: "harveysseed",
    border: "#659ac2",
    description:
      "A regional seed company brand launch with a custom website built with Hugo and tech sheets built with nextjs and ReactPDF.",
  },
  {
    name: "AgraScout",
    technology: ["cordova", "cakePhP", "backbone", "bootstrap", "leaflet"],
    deliverables: [
      "brand assets",
      "user research",
      "product UI/UX",
      "website",
      "front-end development",
      "customer support",
      "sales",
    ],
    tags: ["backbone", "cakephp", "bootstrap"],
    thumbnail: "agrascout-thumb.png",
    hero: "/agrascout-hero.jpg",
    slug: "agrascout",
    border: "#53ac55",
    description:
      "A crop scouting app for independent agronomists, retail opertations, and students built with cakePhP, Backbone.js, and HTML5.",
  },
  {
    name: "Harvey Milling",
    technology: ["Squarespace", "Photoshop", "Illustrator", "InDesign"],
    deliverables: ["art direction", "website", "marketing assets"],
    tags: ["squarespace"],
    thumbnail: "harveymilling-thumb.png",
    hero: "/harveymilling-hero.jpg",
    slug: "harveymilling",
    border: "#8e192a",
    description:
      "Digital and physical brand design & a custom e-commerce website built with Squarespace for a farm and garden store.",
  },
  {
    name: "Tillerman Seeds",
    technology: ["Hugo", "tailwind"],
    deliverables: ["logo", "website", "business card"],
    tags: ["hugo", "tailwind"],
    thumbnail: "tillerman-thumb.png",
    hero: "/tillerman-hero.jpg",
    slug: "tillerman",
    border: "#1d4861",
    description:
      "Digital brand design for a new, national specialty seed holding company. Website built with Hugo.",
  },
  {
    name: "Legacy Seed Companies",
    technology: ["Hugo", "tailwind"],
    deliverables: ["logo", "website", "business card"],
    tags: ["hugo", "tailwind"],
    thumbnail: "legacy-thumb.png",
    hero: "/legacy-hero.jpg",
    slug: "legacy",
    border: "#f0943c",
    description:
      "Digital brand redesign for a national specialty seed holding company after a change of ownership. Website built with Hugo.",
  },
];
