import React from "react";
import { useLocation } from "react-router";

function Cropinspector() {
  const { state } = useLocation();
  return (
    <>
      {state && (
        <>
          <div className="title-wrapper">
            <h1>Crop Inspector</h1>
          </div>
          <div className="content-wrapper">
          <div className="hero-wrapper">
            <img src={state.hero} alt={state.name} className="hero-image" width={900} height={500}/>
          </div>
          <div className="work-wrapper">
            <div className="subtitle-wrapper">
              <h2>Technology</h2>
              {state.technology.map((technology, index) => {
                return <span key={index}>{technology}</span>;
              })}
              <h2>Deliverables</h2>
              {state.deliverables.map((deliverable, index) => {
                return <span key={index}>{deliverable}</span>;
              })}
            </div>
            <div className="content">
                <h3>React Native forms</h3>
                <h4>Introduction</h4>
                <p>We were consulted to create an in-field app to record specific crop criteria in a fluid process.</p>
                <h4>Background</h4>
                <p>We had experience with in-field applications from our crop scouting app but because the project was small and niche we wanted to use the newly public React Native instead of our prior web wrapper build workflow that was cumbersome and slow and most likely unnecessary in this situation. I designed the logo, brand, UI, and wrote the front-end using React Native.</p>
                <figure className="image-wrapper">
                  <img src="/cropinspector-views.jpg" alt="Crop Inspector views" width="800" height="600" />
                  <figcaption>Crop Inspector UI</figcaption>
                </figure>
                <h4>Challenges</h4>
                <p>We had to create a multi-step form that could be used in the field by a wide variety of user technical skillsets easily and quickly. Early React was infamous for its form building complexity and we felt that pain.</p>
                <h4>Technical Solutions</h4>
                <p>We used tcomb-form-native to create a form that could be easily extended and modularly modified. Using the compostability of React components, we found React Native a breeze to build with and test native interfaces.</p>
          </div>
        </div>
          </div>
          </>
      )}
    </>
  );
};

export default Cropinspector;
