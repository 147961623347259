import React from "react";
import me from "./img/me.jpg";
import linkedin from "./img/linkedin.svg";
import github from "./img/github.svg";
import dribbble from "./img/dribbble.svg";
import codepen from "./img/codepen.svg";
import unsplash from "./img/unsplash.svg";

function About() {
  return (
    <>
      <div className="title-wrapper">
        <h1>About</h1>
      </div>
      <div className="content-wrapper">
        <img
          src={me}
          alt="Me"
          className="profile-picture"
          height={150}
          width={150}
        />
        <div className="social-link-list">
          <a
            href="https://www.linkedin.com/in/jamisonsheppard/"
            className="social-link"
          >
            <img src={linkedin} alt="LinkedIn" height={30} width={30} />
          </a>
          <a href="https://github.com/sheppardjm/" className="social-link">
            <img src={github} alt="Github" height={30} width={30} />
          </a>
          <a
            href="https://dribbble.com/jamisonsheppard"
            className="social-link"
          >
            <img src={dribbble} alt="Dribbble" height={30} width={30} />
          </a>
          <a href="https://codepen.io/sheppardjm" className="social-link">
            <img src={codepen} alt="Codepen" height={30} width={30} />
          </a>
          <a href="https://unsplash.com/@sheppardjm" className="social-link">
            <img src={unsplash} alt="Unsplash" height={30} width={30} />
          </a>
        </div>
        <p class="leading">
          I’m a software developer with a background in design, technical
          writing, and agriculture.
        </p>
        <p>
          In college I worked two summers as an agronomy intern: scouting
          fields, writing reports, reading plat maps. In my downtime—
          <span class="italic">or when it was raining</span>—I worked on the
          company website. That experience landed me a new internship with a
          local startup that wanted to build a crop scouting app. The next three
          years of my life were spent designing, building, and selling something
          we called AgraScout. As the demands of the job changed over the years,
          I transitioned from front-end development to full-stack, eventually
          writing everything from component libraries to APIs, but always
          maintaining my skill and love of design through a number of new brand
          projects.
        </p>
      </div>
    </>
  );
}

export default About;
