import { Application } from "./Application";

function init() {
  new Application();
}

const main = (app) => {
  init(app);
};

export default main;
