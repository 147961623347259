import React from "react";
import { useLocation } from "react-router";

function Legacy() {
  const { state } = useLocation();
  return (
    <>
      {state && (
        <>
          <div className="title-wrapper">
            <h1>Legacy Seed Companies</h1>
          </div>
          <div className="content-wrapper">
            <div className="hero-wrapper">
              <img src={state.hero} alt={state.name} className="hero-image" width={900} height={500}/>
            </div>
            <div className="work-wrapper">
              <div className="subtitle-wrapper">
                <h2>Technology</h2>
                {state.technology.map((technology, index) => {
                  return <span key={index}>{technology}</span>;
                })}
                <h2>Deliverables</h2>
                {state.deliverables.map((deliverable, index) => {
                  return <span key={index}>{deliverable}</span>;
                })}
              </div>
              <div className="content">
                <h3>A rebrand of a new brand</h3>
                <h4>Introduction</h4>
                <p>Legacy Seed Companies was the new iteration of Tillerman Seeds after the company changed hands.</p>
                <h4>Background</h4>
                <p>I was tasked with a rebrand using the new name and no other direction. One of the main touchpoints of the brand would be the business cards so I put a lot of focus into how the monogram could work as a background.</p>
                <figure className="image-wrapper">
                  <img src="/legacy-views.jpg" alt="Tillerman business card" width="800" height="600" />
                  <figcaption>Legacy Seed Companies business cards</figcaption>
                </figure>
                <h4>Challenges</h4>
                <p>With autonomy in project direction, I tackled the complexity of the entire rebrand with thorough research and creative iteration involving the various stakeholders. Whereas the previous iteration of the brand had its design dictated by name, "Legacy" had less obvious brand recognition but I still wanted to make the logo metaphorical.</p>
                <figure className="image-wrapper">
                  <img src="/legacy-views-2.jpg" alt="Legacy Seed Companies brand sheet" width="800" height="600" />
                  <figcaption>Legacy Seed Companies brand sheet</figcaption>
                </figure>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Legacy;
