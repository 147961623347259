import * as PIXI from "pixi.js";
/**
 * Droplet Class
 */
export class Droplet extends PIXI.Sprite {
  /**
   * Droplet constructor
   */
  constructor(texture) {
    super(texture);

    this.mass = 0;
  }
}
