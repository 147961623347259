import * as PIXI from "pixi.js";
import { EffectCanvas } from "./EffectCanvas";
import Alpha from "./img/alpha.png";
import Shine from "./img/shine.png";
import Background from "./img/background.jpg";
import Foreground from "./img/foreground.jpg";
/**
 * Application Class
 * Bootstraps the entire application and initializes all objects
 */
export class Application {
  /**
   * Application constructor
   */
  constructor() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;

    // Define the assets that PIXI needs to preload and initialize the application
    this.loader = PIXI.loader
      .add(Alpha)
      .add(Shine)
      .add("background", Background)
      .add("foreground", Foreground)
      .load(() => this.initialize());
  }

  /**
   * Initialize is ran when the image loader is done loading all resources
   * @return void
   */
  initialize() {
    // Create a new instance of the EffectCanvas which is going to produce all of the visuals
    this.effectCanvas = new EffectCanvas(this.width, this.height, this.loader);

    // Resize listener for the canvas to fill browser window dynamically
    window.addEventListener("resize", () => this.resizeCanvas(), false);

    // Start the initial loop function for the first time
    this.loop();
  }

  /**
   * Simple resize function. Reinitializing everything on the canvas while changing the width/height
   * @return {void}
   */
  resizeCanvas() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;

    this.effectCanvas.resize(this.width, this.height);
  }

  /**
   * Update and render the application at least 60 times a second
   * @return {void}
   */
  loop() {
    window.requestAnimationFrame(() => this.loop());

    this.effectCanvas.update(this.width, this.height);
    this.effectCanvas.render();
  }
}
