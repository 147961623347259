import React from "react";
import { work } from "./work-data";
import { Link } from "react-router-dom";
import BlogList from "./BlogList";

const WorkList = () => {
  return (
    <div className="work-list">
      {work.map((job, index) => {
        return (
          <Link to={`/work/${job.slug}`} state={job} key={index}>
            <div key={index} className="work-item">
              <img
                className="work-thumbnail"
                alt={`${job.name} thumbnail`}
                src={`${job.thumbnail}`}
              />
              <div className="work-details">
                <h2 className="work-title">{job.name}</h2>
                <p className="work-description">{job.description}</p>
                <div className="tag-list">
                  {job.tags.map((tag, index) => {
                    return (
                      <span key={index} className="work-tags">
                        {tag}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

function Work() {
  return (
    <>
      <div className="title-wrapper">
        <h1>Work</h1>
      </div>
      <div className="content-wrapper">
        <div className="projects-wrapper">
          <BlogList />
          <WorkList />
        </div>
      </div>
    </>
  );
}

export default Work;
