import React from "react";
import { useLocation } from "react-router";

function Farmcycle() {
  const { state } = useLocation();
  return (
    <>
      {state && (
        <>
          <div className="title-wrapper">
            <h1>FarmCycle</h1>
          </div>
          <div className="content-wrapper">
          <div className="hero-wrapper">
            <img src={state.hero} alt={state.name} className="hero-image" width={900} height={500}/>
          </div>
          <div className="work-wrapper">
            <div className="subtitle-wrapper">
              <h2>Technology</h2>
              {state.technology.map((technology, index) => {
                return <span key={index}>{technology}</span>;
              })}
              <h2>Deliverables</h2>
              {state.deliverables.map((deliverable, index) => {
                return <span key={index}>{deliverable}</span>;
              })}
            </div>
            <div className="content">
              <h3>Pocket producer</h3>
                <h4>Introduction</h4>
                  <p>We inherited a web-based PHP nutrient accounting application for dairy, swine, and poultry producers and the job fell to me to try and build a mobile counterpart for the farm manager.</p>
                <h4>Background</h4>
                  <p>FarmCycle has had many lives, including starting its web-based journey as a Microsoft Access project in my childhood basement where my dad hacked away at turning an unwieldly Excel spreadsheet into a database. Twenty years later and I had the same honor when writing the API with GraphQL and AWS AppSync.</p>
                <figure className="image-wrapper">
                  <img src="/farmcycle-views.jpg" alt="AgraScout views" width="800" height="600" />
                  <figcaption>Early FarmCycle figma views</figcaption>
                </figure>
                <h4>Challenges</h4>
                <p>After building FarmCycle on AWS Amplify we realized we didn't have the multi-tenancy and role permissions necessary to roll it out to our clients so I had to rebuild the app on the t3-turbo stack using Kinde as the auth solution. The issue with t3 was our version didn't use Next's app router—and Kinde needed it—so we had to start a new repo again, but I'm a big fan of sharing an API across web and native projects and keeping all the dependencies bundled together in a monorepo.</p>
                <h4>Technical Solutions</h4>
                <p>In the past, some of the UI dependencies we used always seemed to have a quirk or bug that made using them a pain over time. For that reason I built all the UI components from scratch: checkboxes, radios, inputs, and other form and UI components so that we had complete control over them and only built the features we needed and nothing more.</p>
                <p>Besides implementing multi-tenancy in Kinde, writing and implementing our custom component code, I also rewrote the API using Prisma and led devOps in publishing to app stores and pushing updates.</p>
            </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Farmcycle;
