import React, { createRef } from "react";
import About from "./About";
import Work from "./Work";
import Contact from "./Contact";
import Agrascout from "./Agrascout";
import Harveymilling from "./Harveymilling";
import Harveysseed from "./Harveysseed";
import Legacy from "./Legacy";
import Tillerman from "./Tillerman";
import Cropinspector from "./Cropinspector";
import Farmcycle from "./Farmcycle";
import Home from "./Home";
import Unknown from "./Unknown";
import Blog from "./Blog";
import BlogList from "./BlogList";

const routes = [
  {
    path: "/",
    name: "Home",
    element: <Home />,
    nodeRef: createRef(),
  },
  {
    path: "/about",
    name: "About",
    element: <About />,
    nodeRef: createRef(),
  },
  {
    path: "/work",
    name: "Work",
    element: <Work />,
    nodeRef: createRef(),
  },
  {
    path: "/contact",
    name: "Contact",
    element: <Contact />,
    nodeRef: createRef(),
  },
  {
    path: "/work/agrascout",
    name: "AgraScout",
    element: <Agrascout />,
    nodeRef: createRef(),
  },
  {
    path: "/work/harveymilling",
    name: "Harveymilling",
    element: <Harveymilling />,
    nodeRef: createRef(),
  },
  {
    path: "/work/harveysseed",
    name: "Harveysseed",
    element: <Harveysseed />,
    nodeRef: createRef(),
  },
  {
    path: "/work/tillerman",
    name: "Tillerman",
    element: <Tillerman />,
    nodeRef: createRef(),
  },
  {
    path: "/work/legacy",
    name: "Legacy",
    element: <Legacy />,
    nodeRef: createRef(),
  },
  {
    path: "/work/cropinspector",
    name: "Cropinspector",
    element: <Cropinspector />,
    nodeRef: createRef(),
  },
  {
    path: "/work/farmcycle",
    name: "Farmcycle",
    element: <Farmcycle />,
    nodeRef: createRef(),
  },
  {
    path: "/404",
    name: "404",
    element: <Unknown />,
    nodeRef: createRef(),
  },
  {
    path: "/blog/:slug",
    name: "Blog",
    element: <Blog />,
    nodeRef: createRef(),
  },
  {
    path: "/blogList",
    name: "BlogList",
    element: <BlogList />,
    nodeRef: createRef(),
  },
];

export default routes;
